import React, { FC } from "react"

const Index: FC = () => (
  <div className="bg-gray-light dark:bg-gray-dark p-32">
    <div className="container mx-auto">
      <h1 className="text-6xl">Developer / Software Engineer</h1>
      <p className="text-4xl">Hey, I'm Joshua.</p>
      <p className="text-3xl">I'm a Belfast-based developer, currently working at <a href="https://instil.co/" rel="nofollow">Instil</a>.</p>
      <p className="text-3xl">Previously worked with <a href="https://www.deloittedigital.co.uk/" rel="nofollow">Deloitte Digital</a> in the Gasworks &amp; <a href="https://www.surfsidesolutions.com/"  rel="nofollow">Surfside Solutions</a> down in Annalong, County Down.</p>
      <div className="text-2xl pt-6">
        <a className="pr-6" href="https://www.github.com/joshwjb" rel="nofollow">GitHub</a>
        <a className="pr-6" href="https://www.twitter.com/joshwjb" rel="nofollow">Twitter</a>
        <a href="https://uk.linkedin.com/in/joshu" rel="nofollow">LinkedIn</a>
      </div>
    </div>
  </div>
);

export default Index;